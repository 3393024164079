html, body, #root {
    width: 100%;
    height: 100%;
}
html {
    overflow: hidden;
}
#root {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.App {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    background: #f9f9f9;
    z-index: 2;
}
